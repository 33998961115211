import { useEffect } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Stack, Typography } from "@mui/material";

import "./ShortProdList.css";

interface propsType {
  campaignInfo: any;
  postMsg: any;
}

const ShortCampaign = (props: propsType) => {
  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
    };
  }, []);

  // 부모창에 메세지 보내기
  const postMsg = (callback: any) => {
    if (callback !== undefined && callback !== "") {
      setTimeout(() => {
        props.postMsg(callback, "*");
      }, 200);
    }
  };

  return (
    <>
      <Box
        sx={{
          display:
            props.campaignInfo !== undefined && props.campaignInfo.callback !== undefined && props.campaignInfo.callback !== ""
              ? "flex"
              : "none",
          justifyContent: "flex-end",
          p: "17.5px",
          mb: "18px",
        }}
      >
        <Stack direction="column" spacing={0}>
          <Box sx={{ position: "relative" }}>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                postMsg(props.campaignInfo.callback);
              }}
            >
              <Box component="img" src="/images/icon-more.png" sx={{ width: "2rem" }} />
            </IconButton>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(calc(-50.4% + 0.5px)) !important",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ color: "#ffffff", fontSize: "14px", whiteSpace: "nowrap" }}>{"더보기"}</Typography>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ShortCampaign;
